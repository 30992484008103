@import "~antd/dist/antd.css";

.logo {
  background: rgba(255, 255, 255, 1);
  margin: 16px;
}

.navLink {
  color: rgba(255, 255, 255, 0.65);
}
.navLink:hover {
  color: #fff;
}
.navLink:focus {
  color: #fff;
  text-decoration: none;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(255,255,255,.2);
  margin: 16px;
}

.headercontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center
}